import { type ReactNode } from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import type { SchemaOrgProps } from "../types";

const variants = tv({
  variants: {
    variant: {
      h1: "scroll-m-20 text-[42px] lg:text-[64px] font-bold leading-lg",
      h2: "scroll-m-20 text-[32px] lg:text-[48px] font-bold leading-lg",
      h3: "scroll-m-20 text-[28px] lg:text-[36px] font-bold leading-lg",
      h4: "scroll-m-20 text-[24px] lg:text-[32px] font-bold leading-lg",
      h5: "scroll-m-20 text-[22px] lg:text-[26px] font-bold leading-lg",
      h6: "scroll-m-20 text-[16px] lg:text-[18px] font-bold leading-lg",
      p: "leading-lg [&:not(:first-child)]:mt-6t",
      blockquote: "mt-6 border-l-2 pl-6 italic",

      list: "my-6 ml-6 list-disc [&>li]:mt-2",
      lead: "text-xl text-muted-foreground",
      large: "text-lg font-semibold",
      small: "text-sm font-medium",
      muted: "text-sm text-muted-foreground",
      inlineCode:
        "relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold",
    },
    color: {
      default: "inherit",
      primary: "text-primary/90",
      subtle: "text-primary/50",
      notice: "text-notice",
      contrast: "text-contrast/90",
    },
    size: {
      fine: "subpixel-antialiased",
      display: "font-bold text-display",
      heading: "font-bold text-heading",
      lead: "font-bold text-lead",
      copy: "font-medium text-copy",
    },
    width: {
      default: "max-w-prose",
      narrow: "max-w-prose-narrow",
      wide: "max-w-prose-wide",
    },
    centered: {
      true: "text-center",
    },
    nowrap: {
      true: "nowrap",
    },
  },

  defaultVariants: { variant: "p", centered: false },
  compoundVariants: [
    {
      variant: ["h1", "h2", "h3", "h4"],
      centered: true,
    },
  ],
});
type Variants = VariantProps<typeof variants>;
type Props = Variants &
  SchemaOrgProps & {
    as?: React.ElementType;
    children: ReactNode;
    className?: string | undefined;
  };
const defaultAs = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  p: "p",
  blockquote: "blockquote",
  list: "ul",
  lead: "p",
  large: "p",
  small: "p",
  muted: "p",
  inlineCode: "code",
};
export const Text = ({ as, children, className, ...variant }: Props) => {
  const Component = as ?? defaultAs[variant.variant ?? "p"];
  return (
    <Component className={variants({ ...variant, className })}>
      {children}
    </Component>
  );
};

export const H1 = (props: Props) => <Text variant="h1" {...props} />;
export const H2 = (props: Props) => <Text variant="h2" {...props} />;
export const H3 = (props: Props) => <Text variant="h3" {...props} />;
export const H4 = (props: Props) => <Text variant="h4" {...props} />;
export const P = (props: Props) => <Text variant="p" as="p" {...props} />;
export const Blockquote = (props: Props) => (
  <Text variant="blockquote" {...props} />
);
export const List = (props: Props) => <Text variant="list" {...props} />;
export const Lead = (props: Props) => <Text variant="lead" {...props} />;
export const Large = (props: Props) => (
  <Text variant="large" as="span" {...props} />
);
export const Small = (props: Props) => <Text variant="small" {...props} />;
export const Muted = (props: Props) => <Text variant="muted" {...props} />;
export const InlineCode = (props: Props) => (
  <Text {...props} variant="inlineCode" as="p" />
);
export default Text;
